import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "../../assets/style/section-trajet.css"
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoibmF0aGFuYWNjZW50dXJlIiwiYSI6ImNrN2RoZmxvaDA5dGczbG55NDM5N3dhN28ifQ.mHEPYdUr7DFv69toEjZxOQ';

function TrajetCompose({
  o
}) {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(4.579);
  const [lat, setLat] = useState(50.7157);
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    new mapboxgl.Marker({color: "#ff5757",}).setLngLat([4.579, 50.7157]).setPopup(new mapboxgl.Popup().setHTML("<p>Départ et arrivée de la course !<p>")).addTo(map.current);
    map.current.on('load', function() {
      map.current.addSource('route', {
          'type': 'geojson',
          'data': {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                  'type': 'LineString',
                  'coordinates': [
                    [
                      4.576599597930908,
                      50.71492539705883
                    ],
                    [
                      4.573177099227905,
                      50.715849273565716
                    ],
                    [
                      4.570693373680115,
                      50.71449402201217
                    ],
                    [
                      4.572291970252991,
                      50.71360748287803
                    ],
                    [
                      4.572485089302063,
                      50.71303003457764
                    ],
                    [
                      4.572640657424927,
                      50.71266657817602
                    ],
                    [
                      4.572973251342773,
                      50.712289531641055
                    ],
                    [
                      4.573501646518707,
                      50.711710368668975
                    ],
                    [
                      4.57267552614212,
                      50.71206194352603
                    ],
                    [
                      4.571830630302429,
                      50.71230142054206
                    ],
                    [
                      4.5714229345321655,
                      50.71238973799805
                    ],
                    [
                      4.571007192134857,
                      50.71255108676679
                    ],
                    [
                      4.570781886577606,
                      50.7126173245216
                    ],
                    [
                      4.570390284061432,
                      50.71268865738362
                    ],
                    [
                      4.569373726844788,
                      50.71266318137393
                    ],
                    [
                      4.568888247013092,
                      50.712709038181416
                    ],
                    [
                      4.568590521812439,
                      50.71275659334177
                    ],
                    [
                      4.568290114402771,
                      50.71280924364162
                    ],
                    [
                      4.568121135234833,
                      50.712868687457444
                    ],
                    [
                      4.567992389202117,
                      50.71296040062547
                    ],
                    [
                      4.567925333976746,
                      50.71282622759671
                    ],
                    [
                      4.566702246665955,
                      50.71184114803007
                    ],
                    [
                      4.565763473510742,
                      50.71113119854121
                    ],
                    [
                      4.56556499004364,
                      50.71105306947183
                    ],
                    [
                      4.564899802207947,
                      50.71064543743261
                    ],
                    [
                      4.564443826675415,
                      50.710343108047496
                    ],
                    [
                      4.563955664634705,
                      50.7099694398212
                    ],
                    [
                      4.563537240028381,
                      50.70963653289259
                    ],
                    [
                      4.562780857086182,
                      50.709093006295824
                    ],
                    [
                      4.562373161315918,
                      50.70881104939137
                    ],
                    [
                      4.561579227447509,
                      50.70842038340804
                    ],
                    [
                      4.560903310775757,
                      50.70818937938197
                    ],
                    [
                      4.560694098472595,
                      50.70808067121115
                    ],
                    [
                      4.560506343841553,
                      50.70796177136067
                    ],
                    [
                      4.5603883266448975,
                      50.707887034157444
                    ],
                    [
                      4.560222029685973,
                      50.70780550252718
                    ],
                    [
                      4.559444189071655,
                      50.70750994917881
                    ],
                    [
                      4.558446407318115,
                      50.70719061358191
                    ],
                    [
                      4.558145999908447,
                      50.70718381918385
                    ],
                    [
                      4.5590633153915405,
                      50.70555313517991
                    ],
                    [
                      4.559326171875,
                      50.70490424306252
                    ],
                    [
                      4.559283256530762,
                      50.70481251413297
                    ],
                    [
                      4.55933153629303,
                      50.70477514303616
                    ],
                    [
                      4.560366868972777,
                      50.704860077304026
                    ],
                    [
                      4.561506807804108,
                      50.70495690218172
                    ],
                    [
                      4.562518000602722,
                      50.70532891169404
                    ],
                    [
                      4.563472867012024,
                      50.705507271372326
                    ],
                    [
                      4.564467966556549,
                      50.70558371102673
                    ],
                    [
                      4.565272629261017,
                      50.70588267381161
                    ],
                    [
                      4.56593245267868,
                      50.70659100416465
                    ],
                    [
                      4.566490352153778,
                      50.706796536868325
                    ],
                    [
                      4.567026793956757,
                      50.70693752159036
                    ],
                    [
                      4.567380845546722,
                      50.70694431602409
                    ],
                    [
                      4.567710757255554,
                      50.70689335774714
                    ],
                    [
                      4.568904340267181,
                      50.70651456615243
                    ],
                    [
                      4.569582939147949,
                      50.70643473076218
                    ],
                    [
                      4.570138156414032,
                      50.706446621148075
                    ],
                    [
                      4.570647776126862,
                      50.70659610002771
                    ],
                    [
                      4.571219086647033,
                      50.70690015218726
                    ],
                    [
                      4.5716509222984305,
                      50.7070598212468
                    ],
                    [
                      4.5721739530563354,
                      50.70710908074049
                    ],
                    [
                      4.572616517543792,
                      50.70709209471402
                    ],
                    [
                      4.573490917682648,
                      50.70690694662641
                    ],
                    [
                      4.57392543554306,
                      50.70686617997678
                    ],
                    [
                      4.574432373046875,
                      50.706912042455116
                    ],
                    [
                      4.575263857841492,
                      50.7070513282254
                    ],
                    [
                      4.575845897197723,
                      50.707114176547236
                    ],
                    [
                      4.576634466648102,
                      50.707073410077825
                    ],
                    [
                      4.577908515930176,
                      50.70706321845491
                    ],
                    [
                      4.5784932374954215,
                      50.707119272353424
                    ],
                    [
                      4.578992128372192,
                      50.70718212058419
                    ],
                    [
                      4.579858481884003,
                      50.707326501335785
                    ],
                    [
                      4.581320285797119,
                      50.707572796885884
                    ],
                    [
                      4.581840634346008,
                      50.707594878492664
                    ],
                    [
                      4.5823073387146,
                      50.70753203081521
                    ],
                    [
                      4.5833855867385855,
                      50.70717192898492
                    ],
                    [
                      4.583578705787659,
                      50.707162586683665
                    ],
                    [
                      4.5829591155052185,
                      50.70738000703083
                    ],
                    [
                      4.582504481077194,
                      50.707706984951415
                    ],
                    [
                      4.58227515220642,
                      50.70782248829518
                    ],
                    [
                      4.581014513969421,
                      50.70837791951805
                    ],
                    [
                      4.580833464860916,
                      50.70845605304593
                    ],
                    [
                      4.580487459897994,
                      50.7086785630314
                    ],
                    [
                      4.579877257347107,
                      50.709173686423306
                    ],
                    [
                      4.580030143260956,
                      50.70927135165499
                    ],
                    [
                      4.5804861187934875,
                      50.709490460738884
                    ],
                    [
                      4.578954577445984,
                      50.71001020377243
                    ],
                    [
                      4.578581750392914,
                      50.71023780184877
                    ],
                    [
                      4.578458368778229,
                      50.71037368077058
                    ],
                    [
                      4.57831084728241,
                      50.71063015116217
                    ],
                    [
                      4.578211605548859,
                      50.710935875623605
                    ],
                    [
                      4.578144550323485,
                      50.71100041719945
                    ],
                    [
                      4.578050673007965,
                      50.71104797409321
                    ],
                    [
                      4.577868282794952,
                      50.71108703864842
                    ],
                    [
                      4.577508866786957,
                      50.711165167661186
                    ],
                    [
                      4.5765164494514465,
                      50.711540524798274
                    ],
                    [
                      4.576076567173004,
                      50.711774909178494
                    ],
                    [
                      4.575929045677185,
                      50.711917577357895
                    ],
                    [
                      4.575583040714264,
                      50.71242880143513
                    ],
                    [
                      4.575615227222443,
                      50.71250013458404
                    ],
                    [
                      4.5761677622795105,
                      50.71261562611879
                    ],
                    [
                      4.576457440853119,
                      50.71273791096311
                    ],
                    [
                      4.577369391918182,
                      50.713289887193135
                    ],
                    [
                      4.577492773532867,
                      50.7135378497837
                    ],
                    [
                      4.577594697475433,
                      50.7140133908079
                    ],
                    [
                      4.577637612819672,
                      50.71448892700759
                    ],
                    [
                      4.577562510967255,
                      50.71462309527701
                    ],
                    [
                      4.577444493770599,
                      50.71469782174157
                    ],
                    [
                      4.577020704746246,
                      50.71481670450783
                    ],
                    [
                      4.576583504676818,
                      50.714920302101135
                    ]
                  ]
              }
          }
      });
      map.current.addLayer({
          'id': 'route',
          'type': 'line',
          'source': 'route',
          'layout': {
              'line-join': 'round',
              'line-cap': 'round'
          },
          'paint': {
              'line-color': '#30a17d',
              'line-width': 5
          }
      });
  });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <section id="section-trajet">
      <article>
        <div ref={mapContainer} className="map-container" />
      </article>
      <article> 
        <div>
          <h2>Le trajet</h2><br /><br /><br />          
        </div>
        <a className='btn' onClick={o}>Je veux m'inscrire!</a>  <br></br>
        
      </article>
      
    </section>
  );
}

export default TrajetCompose;
