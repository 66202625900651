import React from "react";
import "../../assets/style/section-sponsors.css"

function PriceCompose() {
    return (
 
        <section id="section-price">
            <h2>Les tarifs</h2>
            <br></br><br></br><br></br><br></br>
            <figure>
                <center><img class="display-tarif" src={require("../../assets/img/Prix6km.jpg")} /></center>
            </figure>
        </section>


    );
}

export default PriceCompose;
