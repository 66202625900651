import React from "react";
import "../../assets/style/section-sponsors.css"
import sponsors from "../../assets/img/sponsors-illustration.svg"
import LogoUnite from "../../assets/img/Logo40e_2019.svg"
import LogoWik from "../../assets/img/wix_logo.png"
import LogoVex from "../../assets/img/vex.jpg"
import LogoPad from "../../assets/img/pairidaiza.jpg"
import LogoCelia from "../../assets/img/Celia_Logo.jpg"
import LogoCarette from "../../assets/img/carette.png"
import LogoExki from "../../assets/img/xki.jpg"
import LogoPaintball from "../../assets/img/paint.jpg"

function SponsorsCompose() {
    return (
 
        <section id="section-sponsors">
            <h2>Nos sponsors</h2>
            <article>
            <figure>
                <img src={require("../../assets/img/logo wavre .png")} />
            </figure>
            <figure>
                <img src={LogoUnite} />
            </figure>
            <figure>
                <img src={require("../../assets/img/logo_gcb.png")} />
            </figure>
            <figure>
                <img src={LogoWik} />
            </figure>
            <figure>
                <img src={LogoPad} />
            </figure>
            <figure>
                <img src={LogoVex} />
            </figure>
            <figure>
                <img src={LogoCelia} />
            </figure>
            <figure>
                <img src={LogoCarette} />
            </figure>
            <figure>
                <img src={LogoExki} />
            </figure>
            <figure>
                <img src={LogoPaintball} />
            </figure>
            </article>
            <figure id="sponsors-start">
                <img src={sponsors} />
            </figure>
            <br/><h3 className="center">Merci à tous nos sponsors</h3>
        </section>


    );
}

export default SponsorsCompose;
